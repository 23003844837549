import axios from 'axios'
import settings from 'config/settings'

export const sendEmail = async (data) => {
	const {
		// dev,
		apiDebugging,
	} = settings

	/*if (settings.dev){
		console.log("Email Sending Blocked On Development")
		return
	}*/
	try {
		const response = await axios.post(settings.EMAIL_API_ROUTE, data)
		if (apiDebugging) {
			console.log('Email Sent', response)
		}
		return
	} catch (e) {
		console.log(e)
		throw new Error(e)
	}
}
