const DEVELOPMENT = {
	BASE_URL: 'https://www.rittalinternationaltravel.com', // Used by the email and currently in dev mode it needs to be pointing at the production url.
	// BASE_URL: 'http://localhost:3100',

	FIREBASE_API_KEY: 'AIzaSyDWrlS5_3I0WhKhFML7-aXhmMNTQLsmHQ8',
	FIREBASE_AUTH_DOMAIN: 'rittal-travel-dev.firebaseapp.com',
	FIREBASE_DATABASE_URL: 'https://rittal-travel-dev.firebaseio.com',
	FIREBASE_PROJECT_ID: 'rittal-travel-dev',
	FIREBASE_STORAGE_BUCKET: 'rittal-travel-dev.appspot.com',
	FIREBASE_MESSAGING_SENDER_ID: '156743692340',
	FIREBASE_APP_ID: '1:156743692340:web:2c32505e3ba52c43',
}

// const PRODUCTION = {
// 	BASE_URL: '',

// 	FIREBASE_API_KEY: 'AIzaSyBAmN6NyJkEBB9ZeOMghCT9TRAOtlije1s',
// 	FIREBASE_AUTH_DOMAIN: 'rittal-travel-prod.firebaseapp.com',
// 	FIREBASE_DATABASE_URL: 'https://rittal-travel-prod.firebaseio.com',
// 	FIREBASE_PROJECT_ID: 'rittal-travel-prod',
// 	FIREBASE_STORAGE_BUCKET: 'rittal-travel-prod.appspot.com',
// 	FIREBASE_MESSAGING_SENDER_ID: '969224550418',
// 	FIREBASE_APP_ID: '1:969224550418:web:1cd3b505cd6da6c5',
// }

export default {
	// dev: false,
	dev: process.env.NODE_ENV === 'development',

	// apiDebugging: false,
	apiDebugging: process.env.NODE_ENV === 'development',

	VERSION: '0.1.4',

	GOOGLE_ANALYTICS_KEY: '',
	EMAIL_API_ROUTE: 'https://ov7qju62dl.execute-api.us-east-1.amazonaws.com/development/sendMail',

	...DEVELOPMENT,
	// ...PRODUCTION,
	// ...(process.env.NODE_ENV === 'development' ? DEVELOPMENT : PRODUCTION),
}
