import axios from 'axios'
import firebase from 'firebase/app'
import 'firebase/auth'

import settings from 'config/settings'

//TODO: Rewrite as async await
export const apiRequest = (method, path, data) => {
	const { API_BASE_URL, apiDebugging } = settings

	const url = API_BASE_URL + '/' + path
	const { uid } = firebase.auth().currentUser

	return new Promise((res, rej) => {
		firebase
			.auth()
			.currentUser.getIdToken()
			.then((token) => {
				const headers = { uid, token }

				if (apiDebugging) {
					console.log(`API REQUEST - ${path}:`, { method, url, headers, data })
				}
				axios({ method, url, headers, data })
					.then((response) => {
						if (apiDebugging) {
							console.log(`API RESPONSE - ${path}:`, response)
						}
						return res(response)
					})
					.catch((error) => rej(error))
			})
	})
}
