import React, { createContext, useState, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'

import { docToDataObject } from 'helpers'

export const AppContext = createContext()

export const AppProvider = (props) => {
	const [user, setUser] = useState()
	const [userLoaded, setUserLoaded] = useState(false)
	const [userModel, setUserModel] = useState()
	const [userModelLoaded, setUserModelLoaded] = useState(false)
	const [appVersions, setAppVersions] = useState()
	const [appVersionsLoaded, setAppVersionsLoaded] = useState(false)
	const [sideNavActive, setSideNavActive] = useState(true)
	const [barTitle, setBarTitle] = useState('')

	useEffect(() => {
		firebase.auth().onAuthStateChanged(
			(user) => {
				if (user) {
					setUser(user)
					setUserLoaded(true)

					//TODO: Replace with direct to FB updating this.
					// const { uid } = user
					// const data = { uid, last_sign_in: new Date().getTime() }
					// apiRequest('POST', 'update_user', data)
				} else {
					setUser()
					setUserLoaded(true)
				}
			},
			(e) => {
				console.error(e)
				setUser()
				setUserLoaded(true)
			},
			() => console.log('onAuthStateChanged Completed')
		)
	}, [])

	useEffect(() => {
		let userWatcher
		if (user && user.uid) {
			userWatcher = firebase
				.firestore()
				.collection('Users')
				.doc(user.uid)
				.onSnapshot(
					(snapshot) => {
						setUserModel(docToDataObject(snapshot))
						setUserModelLoaded(true)
					},
					(e) => {
						console.log(e)
						setUserModel()
						setUserModelLoaded(true)
					}
				)
		}

		return () => {
			if (userWatcher) {
				userWatcher()
			}
			setUserModel()
			setUserModelLoaded(false)
		}
	}, [user])

	useEffect(() => {
		if (userModel && !userModel.active) {
			console.log('USER LOGGIN OUT!!!')
			firebase.auth().signOut()
		}
	}, [userModel])

	//App Version
	useEffect(() => {
		firebase
			.firestore()
			.collection('Versions')
			.doc('web')
			.onSnapshot(
				(snapshot) => {
					setAppVersions(docToDataObject(snapshot))
					setAppVersionsLoaded(true)
				},
				(e) => {
					setAppVersions()
					setAppVersionsLoaded(true)
				}
			)

		return () => {
			setAppVersions()
			setAppVersionsLoaded(false)
		}
	}, [])

	return (
		<AppContext.Provider
			value={{
				user,
				userLoaded,
				userModel,
				userModelLoaded,
				appVersions,
				appVersionsLoaded,
				sideNavActive,
				setSideNavActive,
				barTitle,
				setBarTitle,
			}}
		>
			{props.children}
		</AppContext.Provider>
	)
}
