import React, { Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'

const Front = lazy(() => import('layout/Front/ContentWrapper'))
const Portal = lazy(() => import('layout/Portal/ContentWrapper'))

const App = (props) => {
	return (
		<>
			<Suspense fallback={<div />}>
				<Switch>
					<Route path='/portal/' component={Portal} />
					<Route path='/' component={Front} />
				</Switch>
			</Suspense>
		</>
	)
}

export default App
